import React, { useState } from "react";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/gatsby";
import DefaultButton from "../Buttons/DefaultButton";
import RoundedInputField from "../Fields/RoundedInputField";
import "./index.scss";
import { sendToast } from "../../utils/helpers";
import { CredentialsPayload, Auth } from "../../utils/clever-auth";
import { auth } from "../../utils/Axios";

const IndexPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const signIn = async (e: any): Promise<void> => {
    e.preventDefault();
    Cookies.remove("access");
    Cookies.remove("refresh");
    try {
      const { access } = await auth.fetchAuthTokensWithCredentials({
        username: username.toLowerCase().trim(),
        password,
      } as CredentialsPayload);

      if (access) {
        const user_id = Auth.getUserIdFromToken(access);
        Cookies.set("lastUserId", user_id.toString());
        sendToast("Successfully signed in");
      }
    } catch (e) {
      Sentry.captureException(e);
      const wrappedError: any = e;
      if (
        wrappedError.response &&
        wrappedError.response.data &&
        wrappedError.response.data.detail
      ) {
        sendToast(wrappedError.response.data.detail);
      } else {
        sendToast(
          "There was an error signing in. Please contact your friendly neighborhood developers."
        );
      }
    }
  };

  return (
    <div className="sign-in-page">
      <div className="sign-in">
        <section className="sign-in-container">
          <div className="sign-in-block">
            {/* <Logo /> */}
            <h1>Clever Surveys</h1>
            <form className="form-container" name="sign-in">
              <p>EMAIL</p>
              <RoundedInputField
                id="email"
                placeholder="Enter your email"
                callback={(e: any) => setUsername(e.target.value)}
                value={username}
                autoComplete="email"
              />
              <p>PASSWORD</p>
              <RoundedInputField
                id="password"
                placeholder="Enter your password"
                callback={(e: any) => setPassword(e.target.value)}
                value={password}
                autoComplete="email"
                type="password"
              />
              <DefaultButton
                text="Log In"
                callback={signIn}
                disabled={username === "" || password === ""}
              />
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};
export default IndexPage;
