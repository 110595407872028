import axios from "axios";
import jwtDecode from "jwt-decode";
import { Mutex } from "async-mutex";
import * as Sentry from "@sentry/gatsby";

import { MissingRefreshTokenError } from "./classes";
import {
  AuthConfig,
  CredentialsPayload,
  DjangoAuthTokens,
  ITokenStorage,
  RefreshPayload,
} from "./types";

export default class Auth {
  /**
   * This class is used to fetch tokens from the Django server. It must be instantiated with an AuthConfig object, which is created using the AuthConfigBuilder class.
   * @method getAccessToken() - Returns the access token from the configured storage object if it is not expired, or fetches new tokens from the configured refresh endpoint using a refresh token if it exists. If no refresh token exists, it throws a MissingRefreshTokenError. It uses a mutex object to ensure only one request is made at a time.
   * @method fetchAuthTokensWithCredentials(payload: CredentialsPayload) - Fetches new tokens from the configured token endpoint using credentials.
   * @method fetchAuthTokensWithRefreshToken(payload: RefreshPayload) - Fetches new tokens from the configured refresh endpoint using a refresh token.
   * @method clearTokens() - Clears the tokens from the configured storage object.
   * @static @method getUserIdFromToken(token: string) - Returns the user id from the passed jwt token. Assumes user_id is in the token payload.
   */

  private storage: ITokenStorage;

  private baseUrl: string;

  private tokenEndpoint: string;

  private refreshEndpoint: string;

  private mutex: Mutex;

  private accessTokenKey: string;

  private refreshTokenKey: string;

  private timeBufferInSeconds: number;

  constructor(config: AuthConfig) {
    this.baseUrl = config.baseUrl;
    this.tokenEndpoint = config.tokenEndpoint;
    this.refreshEndpoint = config.refreshEndpoint;
    this.accessTokenKey = config.accessTokenKey;
    this.refreshTokenKey = config.refreshTokenKey;
    this.timeBufferInSeconds = config.timeBufferInSeconds;
    this.storage = config.storage;
    this.mutex = new Mutex();
  }

  public getAccessToken = async (): Promise<string | undefined> => {
    const accessToken = this.storage.getToken(this.accessTokenKey);

    if (accessToken && !this.tokenIsExpired(accessToken)) {
      return accessToken;
    }

    if (this.mutex.isLocked()) {
      // if a refresh is in progress, wait for it to finish, then retry getting the token from storage
      await this.mutex.waitForUnlock();
      return this.getAccessToken();
    }

    const release = await this.mutex.acquire();
    try {
      const refreshToken = this.storage.getToken(this.refreshTokenKey);
      if (!refreshToken) {
        throw new MissingRefreshTokenError();
      }
      const refreshPayload: RefreshPayload = { refresh: refreshToken };
      const tokens = await this.fetchAuthTokensWithRefresh(refreshPayload);
      return tokens.access;
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      release();
    }
  };

  public fetchAuthTokensWithRefresh = async (
    payload: RefreshPayload
  ): Promise<DjangoAuthTokens> => this.fetchAuthTokens(payload);

  public fetchAuthTokensWithCredentials = async (
    payload: CredentialsPayload
  ): Promise<DjangoAuthTokens> => this.fetchAuthTokens(payload);

  public static getUserIdFromToken = (token: string): number => {
    const decoded: { [key: string]: any } = jwtDecode(token);
    return decoded.user_id;
  };

  public clearTokens = (): void => {
    this.storage.removeToken(this.accessTokenKey);
    this.storage.removeToken(this.refreshTokenKey);
  };

  private fetchAuthTokens = async (
    payload: RefreshPayload | CredentialsPayload
  ): Promise<DjangoAuthTokens> => {
    const endpoint = this.getEndpointFromPayload(payload);
    const response = await axios.post(endpoint, payload);
    const tokens: DjangoAuthTokens = response.data;
    this.storeTokens(tokens);
    return tokens;
  };

  private storeTokens = (tokens: DjangoAuthTokens): void => {
    const { access, refresh } = tokens;
    this.storage.setToken(this.accessTokenKey, access);
    this.storage.setToken(this.refreshTokenKey, refresh);
  };

  private getEndpointFromPayload(
    payload: RefreshPayload | CredentialsPayload
  ): string {
    return "refresh" in payload
      ? `${this.baseUrl}${this.refreshEndpoint}`
      : `${this.baseUrl}${this.tokenEndpoint}`;
  }

  private tokenIsExpired = (token: string): boolean => {
    const decoded: { [key: string]: any } = jwtDecode(token);
    const now = new Date();
    const exp = new Date((decoded.exp - this.timeBufferInSeconds) * 1000);
    return exp < now;
  };
}
